<template>
  <div class="content-wrapper">
    <ReceiptPreview ref="Receipt" containerTarget="#print" :receiptData="receipt" :patientData="patient" />
    <div class="w-100 d-flex">
      <button type="button" class="my-5 mx-auto" @click="printReceipt">
        Try to Print
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReceiptPreview from "@/components/ReceiptPreview";

export default {
  name: "ReceiptView",
  components: {
    ReceiptPreview,
  },
  data() {
    return {
      isLoading: false,
      receipt: {
        price: 50000,
        amount: 50000,
        Df: 25000,
        Cf: 25000,
        discountValue: 0,
        discountTotal: 0,
        paidDfDiscounted: 0,
        paidCfDiscounted: 0,
        discountPaidTotal: 0,
        finalDiscount: 0,
        overdue: 0,
        paid: 50000,
        paidDf: 25000,
        paidCf: 25000,
        item: [
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
          {
            apptId: 1277,
            treatmentItemId: 1383,
            price: 50000,
            discountMethod: 0,
            discountType: 0,
            discountPrice: 0,
            doctorDiscountPrice: 0,
            clinicDiscountPrice: 0,
            finalDf: 25000,
            finalCf: 25000,
            amount: 50000,
            finalDiscount: 0,
            overdue: 0,
            paid: 50000,
            paidDf: 25000,
            paidCf: 25000,
            creationDt: "2021-03-14T17:25:32.000+0000",
            name: "ปรึกษา Invisalign",
            doctor: "เชาว์สิน ชัยทนุวงศ์",
            quantity: null,
            dfType: 0,
            df: 50,
            _rowVariant: "lightBlue",
          },
        ],
        updateApptStatusItem: [1277],
        receiptPayment: {
          cash: 6100,
          socialSecurity: 900,
          credit: 40000,
          creditBank1: 0,
          creditBank2: 0,
          transfer: 3000,
          transferBank1: 0,
          transferBank2: 0,
        },
      },
      patient: {
        id: 555,
        clinicId: 1,
        branchId: 1,
        personalType: 1,
        personalId: null,
        titleNameTh: "นางสาว",
        firstNameTh: "เนาวรัตน์",
        lastNameTh: "นุชเทศ",
        titleNameEn: null,
        firstNameEn: "",
        lastNameEn: "",
        nickName: "",
        gender: "",
        mobile: "0816441360",
        email: "",
        lineId: null,
        address: "",
        subDistrict: "",
        district: "",
        province: "",
        country: null,
        postcode: "",
        creationDt: "2021-01-25T06:32:15.000+0000",
        modifyDt: "2021-03-14T17:25:33.000+0000",
        creationUid: 1,
        modifyUid: 1,
        dn: "2564-01-0014",
        dob: null,
        tel: null,
        doctorId: null,
        congenitalDisease: "-",
        allergic: "-",
        note: "",
        contentId: null,
        reqDocItems: [],
        hostName: "http://localhost:8082",
        contentUrl: "",
      },
    };
  },
  created() {
    console.log("--- Receipt Preview ---");
  },
  methods: {
    printReceipt() {
      this.$refs.Receipt.print();
    },
  },
};
</script>

<style>
</style>